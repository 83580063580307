import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import StakeholdersPage from "@/views/StakeholdersPage.vue";
import BulletinPage from "@/views/BulletinPage.vue";
import HistoryPage from "@/views/HistoryPage.vue";
import AllNewsPage from "@/views/AllNewsPage.vue";
import NewsPage from "@/views/NewsPage.vue";
import CardsWrapper from "@/components/CardsWrapper.vue";
import ReportsPage from "@/views/ReportsPage.vue";
import GovernmentClean from "@/views/GovernmentCleans.vue";
import RegulatoryPage from "@/views/RegulatoryPage.vue";
import MediaPublications from "@/views/MediaPublications.vue";
import CentralDepartments from "@/views/CentralDepartments.vue";
import ContactsPage from "@/views/ContactsPage.vue";
import SingleEvent from "@/views/SingleEvent.vue";
import VacanciesAndContests from "@/views/VacanciesAndContests.vue";
import PreventionCorruption from "@/views/PreventionCorruption.vue";
import PublicInformation from "@/views/PublicInformation.vue";
import CollectiveLaborDisputesRegister from "@/views/CollectiveLaborDisputesRegister.vue";
import { TabsConfig } from "@/constants/tabs-config";
import CooperationPage from "@/views/CooperationPage.vue";
import AllEvents from "@/views/AllEvents.vue";
import PartiesEntitiesRegister from "@/views/PartiesEntitiesRegister.vue";
import GalleryPage from "@/views/GalleryPage.vue";
import CollectiveDisputesConflicts from "@/views/areasOfActivities/CollectiveDisputesConflicts.vue";
import CollectiveDisputesAlgorithm from "@/views/areasOfActivities/CollectiveDisputesAlgorithm.vue";
import CollectiveDisputesTrainingLaborArbitrators from "@/views/areasOfActivities/CollectiveDisputesTrainingLaborArbitrators.vue";
import CollectiveDisputesPrevention from "@/views/areasOfActivities/CollectiveDisputesPrevention.vue";
import SocialDialogueConcept from "@/views/areasOfActivities/SocialDialogueConcept.vue";
import SocialDialogueLegislation from "@/views/areasOfActivities/SocialDialogueLegislation.vue";
import SocialDialoguePrinciples from "@/views/areasOfActivities/SocialDialoguePrinciples.vue";
import MediaGallery from "@/views/MediaGallery.vue";
import SocialDialogueDetermination from "@/views/areasOfActivities/SocialDialogueDetermination.vue";
import SocialDialoguePeculiarities from "@/views/areasOfActivities/SocialDialoguePeculiarities.vue";
import SocialDialogueSocioEconomicCouncil from "@/views/areasOfActivities/SocialDialogueSocioEconomicCouncil.vue";
import SocialDialogueForeignExperience from "@/views/areasOfActivities/SocialDialogueForeignExperience.vue";
import SubmitDocuments from "@/views/SubmitDocuments.vue";
import LaborArbitrators from "@/views/LaborArbitrators.vue";
import IndependentMediators from "@/views/IndependentMediators.vue";
import MissionPage from "@/views/MissionPage.vue";
import UsefulLinks from "@/views/UsefulLinks.vue";
import SingleVacancy from "@/views/SingleVacancy.vue";
import GovernmentProcurement from "@/views/GovernmentProcurement.vue";
import AppealPage from "@/views/AppealPageTempSolution.vue";
import PersonPage from "@/views/PersonPage.vue";
import NewsSubscriptionView from "@/views/NewsSubscriptionView.vue";
import AppLayout from "@/AppLayout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/news-subscription",
    name: "news-subscription",
    component: NewsSubscriptionView,
  },
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: HomePage,
      },
      {
        path: "/resources",
        name: "resources",
        component: CardsWrapper,
        props: {
          title: "main.menu.resources.title",
          cards: [
            {
              text: "main.menu.resources.collectiveLaborDisputesRegister",
              routerLink: "/resources/collective-labor-disputes-register",
            },
            {
              text: "main.menu.resources.partiesEntitiesRegister",
              routerLink: "/resources/parties-entities-register",
            },
            {
              text: "main.menu.resources.laborArbitrators",
              routerLink: "/resources/labor_arbitrators",
            },
            {
              text: "main.menu.resources.independentMediators",
              routerLink: "/resources/independent_mediators",
            },
            {
              text: "main.menu.resources.bulletin",
              routerLink: "/resources/bulletin",
            },
            {
              text: "main.menu.resources.links",
              routerLink: "/resources/links",
            },
          ],
        },
        meta: {
          headerTitle: "main.menu.resources.title",
        },
      },
      {
        path: "/resources/bulletin",
        name: "bulletin",
        component: BulletinPage,
        meta: {
          headerTitle: "main.menu.resources.bulletin",
          breadcrumbs: [{ name: "main.breadcrumbs.resources", route: "/resources" }],
        },
      },
      {
        path: "/resources/collective-labor-disputes-register",
        name: "collective-labor-disputes-register",
        component: CollectiveLaborDisputesRegister,
        meta: {
          headerTitle: "main.menu.resources.collectiveLaborDisputesRegister",
          breadcrumbs: [{ name: "main.breadcrumbs.resources", route: "/resources" }],
        },
      },
      {
        path: "/resources/parties-entities-register",
        name: "parties-entities-register",
        component: PartiesEntitiesRegister,
        meta: {
          headerTitle: "main.menu.resources.partiesEntitiesRegister",
          breadcrumbs: [{ name: "main.breadcrumbs.resources", route: "/resources" }],
        },
      },
      {
        path: "/resources/labor_arbitrators",
        name: "labor_arbitrators",
        component: LaborArbitrators,
        meta: {
          headerTitle: "main.menu.resources.laborArbitrators",
          breadcrumbs: [{ name: "main.breadcrumbs.resources", route: "/resources" }],
        },
      },
      {
        path: "/resources/independent_mediators",
        name: "independent_mediators",
        component: IndependentMediators,
        meta: {
          headerTitle: "main.menu.resources.independentMediators",
          breadcrumbs: [{ name: "main.breadcrumbs.resources", route: "/resources" }],
        },
      },
      {
        path: "/resources/links",
        name: "links",
        component: UsefulLinks,
        meta: {
          headerTitle: "main.menu.resources.links",
          breadcrumbs: [{ name: "main.breadcrumbs.resources", route: "/resources" }],
        },
      },
      {
        path: "/about-us",
        name: "about-us",
        component: CardsWrapper,
        props: {
          title: "main.menu.aboutUs.title",
          cards: [
            {
              text: "main.menu.aboutUs.mission",
              routerLink: "/about-us/mission",
            },
            {
              text: "main.menu.aboutUs.history",
              routerLink: "/about-us/history",
            },
            {
              text: "main.menu.aboutUs.departments",
              routerLink: "/about-us/departments",
            },
            {
              text: "main.menu.aboutUs.reports",
              routerLink: "/about-us/reports",
            },
            {
              text: "main.menu.aboutUs.stakeholders",
              routerLink: "/about-us/stakeholders",
            },
            {
              text: "main.menu.aboutUs.cooperation",
              routerLink: "/about-us/cooperation",
            },
            {
              text: "main.menu.aboutUs.contacts",
              routerLink: "/about-us/contacts",
            },
          ],
        },
        meta: {
          headerTitle: "main.menu.aboutUs.title",
        },
      },
      {
        path: "/about-us/cooperation",
        name: "cooperation",
        component: CooperationPage,
        meta: {
          headerTitle: "main.menu.aboutUs.cooperation",
          breadcrumbs: [{ name: "main.breadcrumbs.aboutUs", route: "/about-us" }],
        },
      },
      {
        path: "/about-us/mission",
        name: "mission",
        component: MissionPage,
        meta: {
          headerTitle: "main.menu.aboutUs.mission",
          breadcrumbs: [{ name: "main.breadcrumbs.aboutUs", route: "/about-us" }],
        },
      },
      {
        path: "/about-us/stakeholders",
        name: "stakeholders",
        component: StakeholdersPage,
        meta: {
          headerTitle: "main.menu.aboutUs.stakeholders",
          breadcrumbs: [{ name: "main.breadcrumbs.aboutUs", route: "/about-us" }],
        },
      },
      {
        path: "/about-us/departments",
        name: "departments",
        component: CentralDepartments,
        meta: {
          headerTitle: "main.menu.aboutUs.departments",
          breadcrumbs: [{ name: "main.breadcrumbs.aboutUs", route: "/about-us" }],
        },
      },
      {
        path: "/about-us/departments/principal/:id",
        name: "principal",
        component: PersonPage,
        meta: {
          // headerTitle: "Структура",
          breadcrumbs: [{ name: "main.breadcrumbs.aboutUs", route: "/about-us" }],
        },
      },
      {
        path: "/about-us/contacts",
        name: "contacts",
        component: ContactsPage,
        meta: {
          headerTitle: "main.menu.aboutUs.contacts",
          breadcrumbs: [{ name: "main.breadcrumbs.aboutUs", route: "/about-us" }],
        },
      },
      {
        path: "/about-us/history",
        name: "history",
        component: HistoryPage,
        meta: {
          headerTitle: "main.menu.aboutUs.history",
          breadcrumbs: [{ name: "main.breadcrumbs.aboutUs", route: "/about-us" }],
        },
      },
      {
        path: "/about-us/reports",
        name: "reports",
        component: ReportsPage,
        meta: {
          headerTitle: "main.menu.aboutUs.reports",
          breadcrumbs: [{ name: "main.breadcrumbs.aboutUs", route: "/about-us" }],
        },
      },
      {
        path: "/areas-of-activities",
        name: "areas-of-activities",
        component: CardsWrapper,
        props: {
          title: "main.menu.areasOfActivities.title",
          cards: [
            {
              text: "main.menu.areasOfActivities.collectiveDisputes",
              routerLink: "/areas-of-activities/collective-disputes",
            },
            {
              text: "main.menu.areasOfActivities.socialDialogue",
              routerLink: "/areas-of-activities/social-dialogue",
            },
          ],
        },
        meta: {
          headerTitle: "main.menu.areasOfActivities.title",
        },
      },
      {
        path: "/areas-of-activities/collective-disputes",
        name: "activity-collective-disputes",
        component: CardsWrapper,
        props: {
          title: "main.breadcrumbs.activityDisputes",
          cards: [
            {
              text: "main.menu.areasOfActivities.disputesConflicts",
              routerLink: "/areas-of-activities/collective-disputes/disputes-conflicts",
            },
            {
              text: "main.menu.areasOfActivities.alorithm",
              routerLink: "/areas-of-activities/collective-disputes/algorithm",
            },
            {
              text: "main.menu.areasOfActivities.trainingLaborArbitrators",
              routerLink: "/areas-of-activities/collective-disputes/training-labor-arbitrators",
            },
            {
              text: "main.menu.areasOfActivities.prevention",
              routerLink: "/areas-of-activities/collective-disputes/prevention",
            },
          ],
        },
        meta: {
          headerTitle: "main.breadcrumbs.activityDisputes",
          breadcrumbs: [{ name: "main.breadcrumbs.activities", route: "/areas-of-activities" }],
        },
      },
      {
        path: "/areas-of-activities/collective-disputes/disputes-conflicts",
        name: "collective-disputes-conflicts",
        component: CollectiveDisputesConflicts,
        meta: {
          headerTitle: "main.menu.areasOfActivities.disputesConflicts",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.activityDisputes",
              route: "/areas-of-activities/collective-disputes",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/collective-disputes/algorithm",
        name: "algorithm",
        component: CollectiveDisputesAlgorithm,
        meta: {
          headerTitle: "main.menu.areasOfActivities.alorithm",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.activityDisputes",
              route: "/areas-of-activities/collective-disputes",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/collective-disputes/training-labor-arbitrators",
        name: "training-labor-arbitrators",
        component: CollectiveDisputesTrainingLaborArbitrators,
        meta: {
          headerTitle: "main.menu.areasOfActivities.trainingLaborArbitrators",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.activityDisputes",
              route: "/areas-of-activities/collective-disputes",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/collective-disputes/prevention",
        name: "prevention",
        component: CollectiveDisputesPrevention,
        meta: {
          headerTitle: "main.menu.areasOfActivities.prevention",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.activityDisputes",
              route: "/areas-of-activities/collective-disputes",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/social-dialogue",
        name: "social-dialogue",
        component: CardsWrapper,
        props: {
          title: "main.menu.areasOfActivities.socialDialogue",
          cards: [
            {
              text: "main.menu.areasOfActivities.concept",
              routerLink: "/areas-of-activities/social-dialogue/concept",
            },
            {
              text: "main.menu.areasOfActivities.legislation",
              routerLink: "/areas-of-activities/social-dialogue/legislation",
            },
            {
              text: "main.menu.areasOfActivities.principles",
              routerLink: "/areas-of-activities/social-dialogue/principles",
            },
            {
              text: "main.menu.areasOfActivities.determination",
              routerLink: "/areas-of-activities/social-dialogue/determination",
            },
            {
              text: "main.menu.areasOfActivities.peculiarities",
              routerLink: "/areas-of-activities/social-dialogue/peculiarities-of-submitting-docs",
            },
            {
              text: "main.menu.areasOfActivities.socioEconomicCouncil",
              routerLink: "/areas-of-activities/social-dialogue/socio-economic-council",
            },
            {
              text: "main.menu.areasOfActivities.foreignExperience",
              routerLink: "/areas-of-activities/social-dialogue/foreign-experience",
            },
          ],
        },
        meta: {
          headerTitle: "menu.areasOfActivities.socialDialogue",
          breadcrumbs: [{ name: "main.breadcrumbs.activities", route: "/areas-of-activities" }],
        },
      },
      {
        path: "/areas-of-activities/social-dialogue/concept",
        name: "concept",
        component: SocialDialogueConcept,
        meta: {
          headerTitle: "main.menu.areasOfActivities.concept",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.socialDialogue",
              route: "/areas-of-activities/social-dialogue",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/social-dialogue/legislation",
        name: "legislation",
        component: SocialDialogueLegislation,
        meta: {
          headerTitle: "main.menu.areasOfActivities.legislation",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.socialDialogue",
              route: "/areas-of-activities/social-dialogue",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/social-dialogue/principles",
        name: "principles",
        component: SocialDialoguePrinciples,
        meta: {
          headerTitle: "main.menu.areasOfActivities.principles",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.socialDialogue",
              route: "/areas-of-activities/social-dialogue",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/social-dialogue/determination",
        name: "determination",
        component: SocialDialogueDetermination,
        meta: {
          headerTitle: "main.menu.areasOfActivities.determination",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.socialDialogue",
              route: "/areas-of-activities/social-dialogue",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/social-dialogue/peculiarities-of-submitting-docs",
        name: "peculiarities",
        component: SocialDialoguePeculiarities,
        meta: {
          headerTitle: "main.menu.areasOfActivities.peculiarities",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.socialDialogue",
              route: "/areas-of-activities/social-dialogue",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/social-dialogue/socio-economic-council",
        name: "socioEconomicCouncil",
        component: SocialDialogueSocioEconomicCouncil,
        meta: {
          headerTitle: "main.menu.areasOfActivities.socioEconomicCouncil",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.socialDialogue",
              route: "/areas-of-activities/social-dialogue",
            },
          ],
        },
      },
      {
        path: "/areas-of-activities/social-dialogue/foreign-experience",
        name: "foreignExperience",
        component: SocialDialogueForeignExperience,
        meta: {
          headerTitle: "main.menu.areasOfActivities.foreignExperience",
          breadcrumbs: [
            { name: "main.breadcrumbs.activities", route: "/areas-of-activities" },
            {
              name: "main.breadcrumbs.socialDialogue",
              route: "/areas-of-activities/social-dialogue",
            },
          ],
        },
      },
      {
        path: "/press-center",
        name: "press-center",
        component: CardsWrapper,
        props: {
          title: "main.menu.pressCenter.title",
          cards: [
            {
              text: "main.menu.pressCenter.news",
              routerLink: "/press-center/news",
            },
            {
              text: "main.menu.pressCenter.mediaLibrary",
              routerLink: "/press-center/gallery",
            },
            {
              text: "main.menu.pressCenter.announcements",
              routerLink: "/press-center/events",
            },
            {
              text: "main.menu.pressCenter.pubInMedia",
              routerLink: "/press-center/pub-in-media",
            },
          ],
        },
        meta: {
          headerTitle: "main.menu.pressCenter.title",
        },
      },
      {
        path: "/press-center/news",
        name: "all-news",
        component: AllNewsPage,
        meta: {
          headerTitle: "main.menu.pressCenter.news",
          breadcrumbs: [{ name: "main.breadcrumbs.pressCenter", route: "/press-center" }],
        },
      },
      {
        path: "/press-center/news/:id",
        name: "news",
        component: NewsPage,
        meta: {
          breadcrumbs: [
            { name: "main.breadcrumbs.pressCenter", route: "/press-center" },
            { name: "main.breadcrumbs.news", route: "/press-center/news" },
          ],
        },
      },
      {
        path: "/press-center/events",
        name: "events",
        component: AllEvents,
        meta: {
          headerTitle: "main.menu.pressCenter.announcements",
          breadcrumbs: [{ name: "main.breadcrumbs.pressCenter", route: "/press-center" }],
        },
      },
      {
        path: "/press-center/events/:id",
        name: "event",
        component: SingleEvent,
        meta: {
          breadcrumbs: [
            { name: "main.breadcrumbs.pressCenter", route: "/press-center" },
            { name: "main.breadcrumbs.events", route: "/press-center/events" },
          ],
        },
      },
      {
        path: "/press-center/gallery",
        name: "media-library",
        component: MediaGallery,
        meta: {
          headerTitle: "main.menu.pressCenter.mediaLibrary",
          breadcrumbs: [{ name: "main.breadcrumbs.pressCenter", route: "/press-center" }],
        },
      },
      {
        path: "/press-center/gallery/:id",
        name: "gallery",
        component: GalleryPage,
        meta: {
          breadcrumbs: [
            { name: "main.breadcrumbs.pressCenter", route: "/press-center" },
            { name: "main.breadcrumbs.gallery", route: "/press-center/gallery" },
          ],
        },
      },
      {
        path: "/press-center/pub-in-media",
        name: "pub-in-media",
        component: MediaPublications,
        meta: {
          headerTitle: "main.menu.pressCenter.pubInMedia",
          breadcrumbs: [{ name: "main.breadcrumbs.pressCenter", route: "/press-center" }],
        },
      },
      {
        path: "/for-public",
        name: "for-public",
        component: CardsWrapper,
        props: {
          title: "main.breadcrumbs.forPublic",
          cards: [
            {
              text: "main.menu.forPublic.appealOfCitizens",
              routerLink: "/for-public/appeal",
            },
            {
              text: "main.menu.forPublic.publicInformation",
              routerLink: "/for-public/public-information",
            },
            {
              text: "main.menu.forPublic.governmentCleans",
              routerLink: "/for-public/government-cleans",
            },
            {
              text: "main.menu.forPublic.governmentProcurement",
              routerLink: "/for-public/government-procurement",
            },
            {
              text: "main.menu.forPublic.antiCorruptionPolicy",
              routerLink: "/for-public/prevention-corruption",
            },
            {
              text: "main.menu.forPublic.actualVacancies",
              routerLink: "/for-public/vacancies/actual",
            },
          ],
        },
        meta: {
          headerTitle: "main.breadcrumbs.forPublic",
        },
      },
      {
        path: "/for-public/appeal",
        name: "appeal",
        component: AppealPage,
        meta: {
          headerTitle: "main.menu.forPublic.appealOfCitizens",
          breadcrumbs: [{ name: "main.breadcrumbs.forPublic", route: "/for-public" }],
        },
      },
      {
        path: "/for-public/government-cleans",
        name: "government-cleans",
        component: GovernmentClean,
        meta: {
          headerTitle: "main.menu.forPublic.governmentCleans",
          breadcrumbs: [{ name: "main.breadcrumbs.forPublic", route: "/for-public" }],
        },
      },
      // {
      //   path: "/for-public/submit-documents",
      //   name: "submit-documents",
      //   component: SubmitDocuments,
      //   meta: {
      //     headerTitle: "main.menu.forPublic.submitDocuments",
      //     breadcrumbs: [{ name: "main.breadcrumbs.forPublic", route: "/for-public" }],
      //   },
      // },
      {
        path: "/for-public/vacancies/actual",
        name: "vacancies-actual",
        component: VacanciesAndContests,
        meta: {
          headerTitle: "main.menu.forPublic.actualVacancies",
          breadcrumbs: [{ name: "main.breadcrumbs.forPublic", route: "/for-public" }],
          tabsConfig: TabsConfig.VACANCIES,
          tab: 1,
        },
      },
      {
        path: "/for-public/vacancies/actual/:id",
        name: "vacancies-one",
        component: SingleVacancy,
        meta: {
          headerTitle: "main.menu.forPublic.actualVacancies",
          breadcrumbs: [
            { name: "main.breadcrumbs.forPublic", route: "/for-public" },
            { name: "main.breadcrumbs.vacancies", route: "/for-public/vacancies/actual" },
          ],
        },
      },
      {
        path: "/for-public/vacancies/results",
        name: "vacancies-results",
        component: VacanciesAndContests,
        meta: {
          headerTitle: "main.menu.forPublic.actualVacancies",
          breadcrumbs: [{ name: "main.breadcrumbs.forPublic", route: "/for-public" }],
          tabsConfig: TabsConfig.VACANCIES,
          tab: 2,
        },
      },
      {
        path: "/for-public/vacancies/info",
        name: "vacancies-info",
        component: VacanciesAndContests,
        meta: {
          headerTitle: "main.menu.forPublic.actualVacancies",
          breadcrumbs: [{ name: "main.breadcrumbs.forPublic", route: "/for-public" }],
          tabsConfig: TabsConfig.VACANCIES,
          tab: 3,
        },
      },
      {
        path: "/for-public/public-information",
        name: "public-information",
        component: PublicInformation,
        meta: {
          headerTitle: "main.menu.forPublic.publicInformation",
          breadcrumbs: [{ name: "main.breadcrumbs.forPublic", route: "/for-public" }],
        },
      },
      {
        path: "/for-public/prevention-corruption",
        name: "prevention-corruption",
        component: PreventionCorruption,
        meta: {
          headerTitle: "main.menu.forPublic.antiCorruptionPolicy",
          breadcrumbs: [{ name: "main.breadcrumbs.forPublic", route: "/for-public" }],
        },
      },
      {
        path: "/for-public/government-procurement",
        name: "government-procurement",
        component: GovernmentProcurement,
        meta: {
          headerTitle: "main.menu.forPublic.governmentProcurement",
          breadcrumbs: [{ name: "main.breadcrumbs.forPublic", route: "/for-public" }],
        },
      },
      {
        path: "/regulatory/legislation",
        name: "regulatory-legislation",
        component: RegulatoryPage,
        meta: {
          tabsConfig: TabsConfig.REGULATORY,
          headerTitle: "main.menu.regulatory",
          tab: 1,
        },
      },
      {
        path: "/regulatory/activity",
        name: "regulatory-activity",
        component: RegulatoryPage,
        meta: {
          tabsConfig: TabsConfig.REGULATORY,
          headerTitle: "main.menu.regulatory",
          tab: 2,
        },
      },
      {
        path: "/regulatory/judicial-practice",
        name: "regulatory-judicial-practice",
        component: RegulatoryPage,
        meta: {
          tabsConfig: TabsConfig.REGULATORY,
          headerTitle: "main.menu.regulatory",
          tab: 3,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
